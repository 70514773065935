<template>
    <div>
        <h3 class="border-bottom-low-contrast pbottom-xsmall">
            {{ title }}
        </h3>
        <div class="flex flex-start">
            <p>{{ description }}</p>
            <select-button-group
                class="mleft-small"
                :value="isFeatureEnabled"
                :options="selectOptions"
                @input="toggleFeature"
            />
        </div>
        <div class="card mbottom-xsmall">
            <img
                :src="exampleImageSrc"
                class="border-radius-medium w-100"
            />
        </div>
        <p class="meta">
            {{ $t("admin_features_rtfm") }}
            <a
                class="link"
                :href="docUrl"
                target="_blank"
            >{{ $t("documentation") }}</a>
        </p>
    </div>
</template>

<script>
import SelectButtonGroup from "@/components/SelectButtonGroup";
import {
    mapActions,
    mapGetters
} from "vuex";
import { INTRANET_MODULE_NAME } from "@/store/intranet/intranet";
import { IS_FEATURE_ENABLED } from "@/store/intranet/getters";
import { TOGGLE_FEATURE } from "@/store/intranet/actions";

export default {
    name: "FeatureSection",
    components: { SelectButtonGroup },
    props: {
        featureFlag: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        exampleImageSrc: {
            type: String,
            required: true,
        },
        docUrl: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            enabled: false,
            selectOptions: {
                [this.$t("off")]: false,
                [this.$t("on")]: true,
            },
        };
    },
    computed: {
        ...mapGetters({
            _isFeatureEnabled: INTRANET_MODULE_NAME + IS_FEATURE_ENABLED,
        }),
        isFeatureEnabled() {
            return this._isFeatureEnabled(this.featureFlag);
        }
    },
    methods: {
        ...mapActions({
            _toggleFeature: INTRANET_MODULE_NAME + TOGGLE_FEATURE,
        }),
        toggleFeature() {
            if (!this.isFeatureEnabled) {
                this._toggleFeature(this.featureFlag);
                return;
            }
            this.$modal.show("confirm-modal", {
                confirmText: this.$t("deactivate"),
                title: this.$t("admin_features_disable_happiness_title"),
                text: this.$t("admin_features_disable_happiness_description"),
                warning: true,
                callback: async(confirmed) => {
                    if (confirmed) {
                        this._toggleFeature(this.featureFlag);
                    }
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.card {
    width: 60%;
}
</style>
