<template>
    <div class="feature-settings">
        <div class="mbottom-large">
            <h2>{{ $t("admin_features_title") }}</h2>
            <span>
                {{ $t("admin_features_description") }}
                <a
                    class="link"
                    target="_blank"
                    href="mailto:support@linchpin-hey.com"
                >{{ $t("contact_us") }}</a>
            </span>
        </div>
        <feature-section
            :title="$t('admin_features_happiness_title')"
            :description="$t('admin_features_happiness_description')"
            :feature-flag="IntranetFeatureFlag.companyHappiness"
            :example-image-src="happinessExampleImage"
            doc-url="https://info.seibert-media.net/display/LI/Linchpin+Hey+-+Company+Happiness"
        />
    </div>
</template>

<script>
import FeatureSection from "@/views/intranet/admin/features/FeatureSection";
import { IntranetFeatureFlag } from "@backend/common/feature-flags/types";
import happinessExampleImage from "@web/assets/happiness/example.png";

export default {
    name: "FeatureSettings",
    components: { FeatureSection },
    data() {
        return {
            IntranetFeatureFlag,
            happinessExampleImage,
        };
    }
};
</script>

<style lang="scss" scoped>
.feature-settings {
    max-width: 45rem;
}
</style>
